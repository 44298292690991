export const lang = {
	bannerTitle: "VIP privileges",
	bannerInfo01: "Upgrade VIP membership",
	bannerInfo02: "get bonuses and enjoy various kinds of attractive promos",
	language_code: "language",
	Game: "Game",
	Member: "Member",
	Bonus: "Bonus",
	Name: "Name",
	query: "Search",
	vip_query: "VIP inquery",
	home_page: "Home",
	preferential_activities: "Promotion",
	quizNav: "Quiz",
	online_service: "Customer service",
	viplevel_query: "VIP level inquery ",
	fill_member_account: "account number",
	vip_level: "From {msg}, each of your valid deposits will be automatically accumulated by the system. When the accumulated amount reaches a certain standard, it can be automatically upgraded to a higher level of VIP. The higher the VIP level, the more promotions you participate in and the more bonuses you get. FAW99 will continue to launch more promotions to reward new and old members.",
	vip_level_tip: "Your valid bets on the day will be counted the day after tomorrow, and your VIP level will be updated and promotion bonuses will be issued at that time.",
	vip_level_tip1: "Your valid bets on the day will be counted the day after tomorrow, and your VIP level will be updated and promotion bonuses will be issued at that time.",
	vip_t_exam: "Example",
	vip_exam: "Note: Calculated according to GMT+8 time.",
	vipTh: [
		"VIP Level",
		"Amount Deposit",
		"VIP Upgrade Bonus",
		"Lucky Bonus VIP",
		"VIP Birthday Bonus",
		// "Monthly deposit offer",
		"Festival VIP Bonus",
		"Monthly Negative Bonus VIP",
		// "VIP Exclusive Domain Name",
		"Customer service 24 hours"
	],
	queryTh: [
		"Upgrade Bonus",
		"Lucky Promotion",
		"Birthday Bonus",
		"Holidays Bonus",
		"Negative profit subsidy",
		// "monthly deposit offer",
		"Exclusive domain",
		"Exclusive domain service",
	],
	tabData: [
		"Member privilege",
		// "Lukcy Bonus ",
		// "Monthly deposit offer",
		"Privilege Description",
	],
	query_result: "search result",
	dear: "dear",
	now_viplevel: "current VIP level",
	current_active_bets: "current valid bet",
	current_save_sum: " total deposit",
	next_bet_need: "require bet",
	next_need_save: "require deposit",
	level_after_promotion: "Level after upgrade ",
	tab2_p1: "How to apply for monthly deposit discount",
	tab2_p2: "Members only need to have 3 deposits of 100 or more or 1 deposit and betting records of 3000 or more in the week before each application, and deposit again to qualify for participation. (The offer is only valid after depositing and not applying before the game!) The details are as follows:",
	tab1Th: ["VIP Level", "Amount Deposit", "Lucky Bonus VIP"],
	tab2Th: ["VIP level", "Deposit offer", "Number of applications", "Discount cap", "Withdrawal requirements"],
	tab1_p1: "How to apply for Lucky Prize？",
	tab1_p2: "Lucky prizes: No application is required. The system will be issued one after another after 06:00 on the 5st of each month (GMT+8). Due to the large number of prizes, there may be delays in the receipt of the prize. Please wait patiently if it is not received in time.",
	tab1_p2_u: "Lucky Bonus : The system will calculate the end of every 5th day of the month. Contact line within 5-10 days to submit a bonus request.",
	tab1_p3: "Members need to reach the corresponding VIP level and the required effective deposit before they can get the corresponding good luck money.",
	tab1_p4: "Example: The member's VIP level for the current month is VIP3, and the effective deposit for the current month is 1000. The next month can only get 18 good luck money corresponding to VIP level 1.",

	tab3_p1: "How to apply for VIP promotion?",
	tab3_p2: "If the valid deposit amount reaches the corresponding level, you can automatically upgrade to a higher level of VIP. The promotion bonus will be issued around 02:00 the next day (GMT+8).",
	tab3_p3: "How to apply for monthly negative interest bonus?",
	tab3_p4: "If the member's monthly negative profit reaches 3000THB or more, this discount does not need to be applied. The system will send you the corresponding bonus on the 15th of every month. There is no upper limit to the bonus amount. Up to 5% of the company's transfer bonus can be obtained.",
	tab3_p5: "How to apply for a birthday gift?",
	tab3_p6: "Contact the online customer service to provide a valid certificate, and the event specialist will send you the corresponding bonus on your birthday!",
	tab3_p7: "",
	tab3_p8: "How do I request a holiday gift?",
	tab3_p9: "There is no need to apply, members only need to deposit a valid deposit of more than 2000THB within 15 days before the festival to qualify, the system will send you the corresponding information on  New Year's Day, Chinese New Year, Songkran Festival,  Labor Day, Loy Krathong Festival bonus.",
	tab3_p9_u: "",
	tab3_p10: "How to apply for the monthly good luck bonus?",
	tab3_p11: "Just deposit 1000THB every month, and the system will automatically issue a good luck bonus on the 5th of the next day. Good luck and fortune.",
	tab3_p12: "",
	tab3_p13: "",
	tab3_p14: "Dear VIP members: Thank you very much for your continuous support and company. As long as you are VIP3 or above, you can apply for a customized exclusive domain name and enjoy the luxury privileges of FAW99 entertainment VIP domain name",
	tab3_p15: "Promotion bonus: contact LINE customer service to apply, the system will complete the transmission on the 10th of each month (GMT+8), and contact LINE customer service to apply within the 10th to the 15th. Due to the large number of bonuses, there may be a delay in arriving into the account. If you do not arrive in time, please contact us several times!",
	tab3_p16: "Lost bonus: contact LINE customer service to apply, the system will complete the transmission on the 15th of each month (GMT+8), and contact LINE customer service from the 15th to the 20th to apply. If the monthly negative profit of members reaches more than 3,000 yuan, the company can get the highest gift from the company. 5% transfer bonus, there is no upper limit on the bonus amount.",


	wan: "ten thousand",
	qianwan: "ten million",
	yi: "100 million",
	qianyi: "100 billion",
	wanyi: "trillion",
	qianwanyi: "quadrillion",
	bei: " ×1",
	vipprivileges: " privileges",
	no_account: "no account",
	// 手机端
	officialIndex: "Home",
	VIPquery: "VIP inquery",
	specialOffer: "Promotion",
	onlineCustomerService: "Customer Service",
	Line: "Line",
	//搜索
	VIPLevelQuery: "VIP inquery",
	PleaseInputAccount: "account Number",
	Query: "Search",
	//tab切换1
	MemberAdvantage: "Member privileges ",
	MemberContent: "From {msg}, every valid bet you make will be automatically accumulated by the system. When the accumulated amount reaches a certain standard, it can be automatically upgraded to a higher level VIP.The higher the VIP level, the more preferential activities you can participate in and the more bonuses you can get. Platform will continue to launch more preferential activities to reward new and old members.",
	MemberTips: "Example: Member a has accumulated effective bets of 100,000 since opening an account and can be promoted to VIP3 (8+18+38+88=152 promotion prizes can be obtained), and 300,000 effective bets can be generated to be promoted to vip4 (8+18+38+88+188=340 promotion bonus), the more valid bets generated, the higher the VIP level, and the more VIP discounts and privileges you can enjoy!",
	VIPLevel: "VIP level",
	CumulativeEffectiveBetting: "Amount Deposit",
	PromotionFiled: "VIP Upgrade Bonus",
	HolidayFiled: "Festival VIP Bonus",
	BirthdayHandsel: "VIP Birthday Bonus",
	MonthlyDiscount: "Monthly deposit offer",
	LuckyMoney: "Lucky Bonus VIP",
	NegativeGold: "Monthly Negative Bonus VIP",
	ExclusiveDomainName: "VIP Exclusive Domain Name",
	DedicatedService: "Customer service 24 hours",
	//tab切换2
	LuckyMoneyTit: "Lukcy Bonus ",
	HowApplyLuckyMoney: "How to apply for Lucky Prize？",
	LuckyContent1: "Lucky prizes: No application is required. The system will be issued one after another after 06:00 on the 5st of each month (GMT+8). Due to the large number of prizes, there may be delays in the receipt of the prize. Please wait patiently if it is not received in time.",
	LuckyContent1_u: "Lucky Bonus : The system will calculate the end of every 5th day of the month. Contact line within 5-10 days to submit a bonus request.",
	LuckyContent2: "Members need to reach the corresponding VIP level and the required effective deposit before they can get the corresponding good luck money.",
	LuckyContent3: "Example: The member's VIP level for the current month is VIP3, and the effective deposit for the current month is 100,000. The next month can only get 88 good luck money corresponding to VIP level 3.",
	LuckyLevel: "VIP level",
	MonthlyDeposit: "Effective monthly deposit",
	DowryGoodLuck: "Lucky prizes",
	//tab切换3
	LavishGifts: "Monthly deposit offer",
	HowApplyMonthlyDepositDiscount: "How to apply for monthly deposit discount？",
	LavishContent: "Members only need to have 3 deposits of 100 or more or 1 deposit and betting records of 3000 or more in the week before each application, and deposit again to qualify for participation. (The offer is only valid after depositing and not applying before the game!) The details are as follows:",
	Lavishlevel: "VIP level",
	softLoan: "Deposit offer",
	NumberRequested: "Number of applications",
	OfferOnline: "Discount cap",
	withdrawals: "Withdrawal requirements",
	//tab切换4
	PrivilegeThat: "Privileges description",
	HowMonthlyNegativeInterestTranshipmentPayment: "How to apply for monthly negative transfer payment？",
	PrivilegeContent1: "If the member’s monthly negative profit reaches 3,000 THB or more, you can get up to 5% transfer bonus from the company. This discount does not need to apply. The system will send you the corresponding bonus on the 15rd of each month. There is no upper limit on the bonus amount.",
	PrivilegeContent2: "How to apply for birthday gift？",
	PrivilegeContent3: "Contact online customer service to provide a valid certificate, and the event specialist will send you the corresponding bonus on your birthday!",
	PrivilegeContent4: "（In order to ensure the normal delivery of the winnings, please contact the customer service specialist to complete the birthday information!）",
	PrivilegeContent5: "How to apply for holiday gifts？",
	PrivilegeContent6: "Lottery rules: Members only need to make a valid deposit of more than 2000 within 15 days before the holiday to be eligible for lottery.",
	PrivilegeContent6_u: "Lottery rules: Members only need to make a valid deposit of more than 2000 within 15 days before the holiday to be eligible for lottery.",
	PrivilegeContent7: "About 24-hour service",
	PrivilegeContent8: "We provide you with 24*7 hours of uninterrupted customer service, online deposit and withdrawal, and game betting. If you need help, please feel free to consult 24-hour online customer service.",
	PrivilegeContent9: "What is a VIP exclusive domain name？",
	PrivilegeContent10: "Members above VIP3 level can consult the line specialist to submit an application.",
	PrivilegeContent11: 'Participating in Platform VIP activities means that you agree to the "General Preferential Rules and Terms',
	//弹窗
	QueryResult: "Result",
	Exalted: "Dear：",
	YourCurrentVIPLevel: "current vip level：",
	CurrentActiveBets: "current valid bet：",
	CurrentActBets: "total deposit：",

	BetsAreNeededQualify: "require bet",
	VIPLevelAfterPromotion: "Level after upgrade",
	Close: "Close",
	Birthday: "Birthday Bonus",
	Holiday: "holiday promotion",
	Negative: "negative profit subsidy",
	Monthly: "monthly deposit ",
	exclusiveLine: "Exclusive domain",
	exclusiveService: "Exclusive domain service",
	noData: "no Data",
	privilege: " privilege",
	myriad: "ten thousand",
	AHundredMillion: "100 million",
	double: " ×1",
	changeLanguage: "Language",
	click_collection: "One-click collection",
	get_title: "Dear Platform players, congratulations on getting",
	get_fail: "Failed to claim! Please try again later",
	concern: "confirm",
	tipBottom: "Example: Member A can be promoted to VIP4 (you can get 8+18+38+88+188=340 promotion bonus) since the account opening, the accumulative amount reaches 30000, the more accumulated valid deposits, the higher the VIP level, and at the same time can enjoy the There will be more vip discounts and privileges!",
	tipBottom02: "If you have any questions, please contact the platform online customer service",
	tipBottom03: "Thailand amount ratio: 1:1",
	tipBottom04: "Vietnam / Indonesia amount ratio: 1:1000",
	promotionType: {
		1: "Upgrade bonus",
		2: "Holiday bonus",
		3: "Birthday bonus",
		4: "Monthly deposit offer",
		5: "Lucky prizes",
		6: "Negative profit subsidy"
	}
}
