import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueI18n from "vue-i18n";
import {
  post,
  get
} from './common/https.js'
import { Dropdown,DropdownMenu,DropdownItem, } from 'element-ui';
Vue.config.productionTip = false
Vue.prototype.$post = post
Vue.prototype.$get = get
Vue.use(VueI18n);
// import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
const i18n = new VueI18n({
  locale: localStorage.getItem("language") || "th", // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
  messages: {
    zh: require("./common/languages/zh.js"), // 本地资源文件，我这里配2个语言，中文&英文，src下根据个人情况放置
    th: require("./common/languages/th.js"),
    en: require("./common/languages/en.js"),
    yn: require("./common/languages/yn.js"),
    id: require("./common/languages/id.js"),
    km: require("./common/languages/km.js"),
    usdt: require("./common/languages/usdt.js"),
    pt: require("./common/languages/pt.js"),
  }
});

router.beforeEach((to, from, next) => {
  if(/ipad|ipod|iphone|Android/i.test(navigator.userAgent) && to.path=='/'){
    next({path:'/about',query:to.query})
  }else{
    next()
  }
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')


