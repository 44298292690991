export const lang = {
  bannerTitle:"Privilégios VIP",
  bannerInfo01:"Atualize para associação VIP",
  bannerInfo02:"Obtenha uma variedade de ofertas especiais",
  language_code:"linguagem",
  Game:"nome do jogo",
  Member:"conta de membro",
  Bonus:"bônus",
  Name:"Nome da oferta",
  query:"procurar",
  vip_query:"Consulta de nível VIP",
  home_page:"primeira página",
  preferential_activities:"Promoções",
  quizNav:"Apostas",
  online_service:"serviço on-line",
  viplevel_query:"Consulta de nível VIP",
  fill_member_account:"Por favor insira sua conta de membro",
  vip_tit:"Bônus VIP",
  vip_level: "As of 2024/8/1, your deposit amount will be automatically accumulated by the system. When the accumulated amount reaches the specified limit You can automatically upgrade to a high VIP level. The higher the VIP level, the more promotions you can participate in. And the bonus you receive will be greater. FAW55 will have promotions coming out regularly to reward new and old members",
  vip_level_tip:"Suas apostas efetivas no dia serão contadas pelo sistema depois de amanhã, e seu nível VIP será atualizado e os bônus de promoção serão emitidos nesse momento.",
  vip_level_tip1:"Suas apostas efetivas no dia serão contadas pelo sistema depois de amanhã, e seu nível VIP será atualizado e os bônus de promoção serão emitidos nesse momento.",
  vip_t_exam:"exemplo",
  vip_exam: "Note: Calculated based on GMT+8 time",
  vipTh: [
    "VIP level",
    "deposit",
    "Upgrade Bonus", //升级奖励 
    "birthday bonus", //生日奖金
    "Loss Bonus",
    "Service staff",
  ],
  queryTh: ["bônus de promoção","Bônus de sorte","bônus de aniversário","Bônus de férias","Ganhos negativos",],
  tabData: [
  "Privilégios VIP",
  // "bônus de boa sorte",
  // "Presente mensal",
  "Descrição do privilégio",
  ],
  query_result:"resultado da pesquisa",
  dear:"Mel",
  now_viplevel:"Seu nível VIP atual",
  current_active_bets:"Apostas válidas atuais",
  current_save_sum:"Depósito total atual",
  next_bet_need:"Apostas são necessárias para avançar",
  next_need_save:"É necessário um depósito para adiantar",
  level_after_promotion:"Nível VIP após promoção",
  tab1Th: ["Nível VIP","depósito","Bônus de upgrade","bônus de aniversário","Bônus de Perda","Equipe de serviço"],
  tab2Th: ["Nível VIP","Oferta de depósito","Número de aplicativos","Oferecer limite superior","pedido de retirada"],
  tab1_p1:"How to apply for an upgrade bonus?",
  tab1_p2:"If the deposit amount reaches the specified limit The system will automatically upgrade VIP. Bonus will be sent out at 02:00 the next day (GMT+8)",
  tab1_p2_u:"",
  tab1_p1_1:"How to apply for monthly deduction?",
  tab1_p1_2:"When members have a total loss of 3,000 or more/month and are at the participating VIP level, you will receive a bonus to increase your luck from us up to 5%. The system will automatically distribute the bonus on the 15th of every month. There is no limit on the bonus amount",
  tab1_p1_3:"How to apply for a birthday bonus?",
  tab1_p1_4:"Contact online customer service to confirm the evidence. After verification, the staff will send you the bonus on your birthday",
  tab1_p1_5:"(For convenience in carrying out the transaction Please contact staff to fill in complete birth date information)",
  tab1_p1_6:"A partir de 01/01/2024, o valor do seu depósito será automaticamente acumulado pelo sistema. Quando o valor acumulado atingir o limite especificado, você poderá atualizar automaticamente para um nível VIP alto. Quanto maior o nível VIP, mais promoções você poderá participar. E o bônus que você receberá será maior. FAW55 terá promoções lançadas regularmente para recompensar membros novos e antigos",
  tab1_p4:"Nota: Calculado com base no horário GMT+8",
  tab2_p1:"Exemplo: O membro A acumulou depósitos desde a abertura da conta de 30.000 e pode atualizar para VIP4. Receba um bônus de atualização total de vip0-vip4 no total. （8+18+38+88+188=340）Quanto maior o valor do depósito cumulativo, maior será o VIP. Ao mesmo tempo, existem muitos descontos e privilégios especiais no nível VIP que você pode desfrutar",
  tab2_p2:"Se você tiver problemas adicionais, entre em contato com a equipe online",
  tab3_p1:"Como solicitar um bônus de upgrade?",
  tab3_p2:"Se o valor do depósito atingir o limite especificado, o sistema atualizará automaticamente o VIP. O bônus será enviado às 02:00 do dia seguinte (GMT+8)",
  tab3_p3:"Como solicitar redução mensal?",
  tab3_p4:"Quando os membros tiverem uma perda total de 3.000 ou mais/mês e estiverem no nível VIP de participação, você receberá um bônus de até 5% para aumentar sua sorte. O sistema distribuirá automaticamente o bônus no dia 15 de cada mês. Não há limite para o valor do bônus",
  tab3_p5:"Como solicitar um bônus de aniversário?",
  tab3_p6:"Entre em contato com o atendimento ao cliente online para confirmar as evidências. Após verificação, a equipe enviará o bônus no seu aniversário",
  tab3_p7:"(Para maior comodidade na realização da transação, entre em contato com a equipe para preencher as informações completas da data de nascimento)",
  tab3_p8:"",
  tab3_p9:"",
  tab3_p9_u:"",
  tab3_p10:"",
  tab3_p11:"",
  tab3_p12:"",
  tab3_p13:"",
  tab3_p14:"FAw99 reserva-se o direito de modificar, cancelar, suspender ou encerrar a promoção ou alterar os termos da promoção a qualquer momento sem aviso prévio.",
  tab3_p15:"",
  tab3_p16:"",
  
  wan:"Dez mil",
  qianwan:"Dez milhões",
  yi:"100 milhões",
  qianyi:"Centenas de bilhões",
  wanyi:"trilhões",
  qianwanyi:"Quadrilhões",
  bei:"vezes",
  vipprivileges:"privilégio",
  no_account:"A conta de membro não existe, insira a conta de membro correta",
  // 手机端
  officialIndex:"primeira página",
  VIPquery:"Consulta de nível VIP",
  specialOffer:"Promoções",
  onlineCustomerService:"serviço on-line",
  Line:"Linha",
  //搜索
  VIPLevelQuery:"Consulta VIP",
  PleaseInputAccount:"Por favor insira sua conta de membro",
  Query:"Investigar",
  //tab切换1
  MemberAdvantage:"Vantagens para membros",
  MemberContent:"A partir de {msg}, todas as apostas válidas que você fizer serão automaticamente acumuladas pelo sistema. Quando o acúmulo atingir um determinado padrão, ele poderá ser atualizado automaticamente para um nível VIP superior. Quanto maior o nível VIP, mais atividades preferenciais e bônus você pode participar, e a plataforma continuará a lançar mais atividades preferenciais para recompensar membros novos e antigos.",
  MemberTips:"Por exemplo: O membro A pode ser promovido para vip3 (pode obter 8+18+38+88=152 bônus de promoção) se as apostas efetivas acumuladas atingirem 100.000 desde a abertura da conta. Se ele gerar outras 300.000 apostas efetivas, ele poderá ser promovido. para vip4 (pode obter 8 + 18 + 38 + 88 + 188 = 340 bônus de promoção), quanto mais apostas eficazes forem geradas, maior será o nível VIP e mais descontos e privilégios VIP você poderá desfrutar!",
  VIPLevel:"Nível VIP",
  CumulativeEffectiveBetting:"Apostas válidas acumuladas",
  PromotionFiled:"bônus de promoção",
  HolidayFiled:"Bônus de férias",
  BirthdayHandsel:"bônus de aniversário",
  MonthlyDiscount:"Desconto de depósito mensal",
  LuckyMoney:"Bônus de sorte",
  NegativeGold:"Ganhos negativos",
  ExclusiveDomainName:"Nome de domínio exclusivo",
  DedicatedService:"Atendimento ao cliente dedicado",
  //tab切换2
  LuckyMoneyTit:"bônus de boa sorte",
  HowApplyLuckyMoney:"Como solicitar bônus de boa sorte?",
  LuckyContent1:"Bônus de Boa Sorte: Não há necessidade de inscrição O sistema distribuirá os bônus um após o outro a partir das 06h00 do dia 5 de cada mês (GMT+8). chegada. Se não chegar a tempo, aguarde pacientemente.",
  LuckyContent1_u:"Bônus de boa sorte: Entre em contato com o atendimento ao cliente da LINE para solicitar. O sistema concluirá a transmissão no dia 5 de cada mês (GMT + 8). pode haver atrasos na chegada, se a conta não for recebida a tempo, entre em contato conosco várias vezes!",
  LuckyContent2:"Os membros devem primeiro atingir o nível VIP correspondente e o depósito efetivo exigido antes de poderem obter o ouro da boa sorte correspondente.",
  LuckyContent3:"Por exemplo: o nível VIP do membro para o mês atual é VIP3, e o depósito efetivo para o mês é de 100.000. No mês seguinte, ele só poderá obter 88 ouro da boa sorte correspondente ao nível VIP 3.",
  LuckyLevel:"Nível VIP",
  MonthlyDeposit:"depósito",
  DowryGoodLuck:"Bônus de upgrade",
  birthdayBonus:"bônus de aniversário",
  lossBonus:"Bônus de Perda",
  serviceStaff:"Equipe de serviço",
  //tab切换3
  LavishGifts:"Presente mensal",
  HowApplyMonthlyDepositDiscount:"Como solicitar desconto de depósito mensal?",
  LavishContent:"Os membros só precisam ter 3 depósitos e registros de apostas superiores a 100 ou 1 de mais de 3.000 na semana anterior a cada inscrição, e podem ser elegíveis para participar depositando novamente (o desconto só é válido após a inscrição antes de fazer um jogo. !) Detalhes a seguir :",
  Lavishlevel:"Nível VIP",
  softLoan:"Oferta de depósito",
  NumberRequested:"Número de aplicativos",
  OfferOnline:"Oferecer limite superior",
  withdrawals:"pedido de retirada",
  //tab切换4
  PrivilegeThat:"Descrição do privilégio",
  HowMonthlyNegativeInterestTranshipmentPayment:"Como solicitar fundos mensais de transferência de lucros negativos?",
  PrivilegeContent1:"Os membros cujo lucro negativo mensal atinja mais de 3.000 yuans podem receber bônus de transferência de até 5% da empresa. Não há necessidade de solicitar este desconto. limite superior do valor do bônus.",
  PrivilegeContent2:"Como solicitar dinheiro para presente de aniversário?",
  PrivilegeContent3:"Entre em contato com o atendimento ao cliente online para fornecer um documento de identidade válido e o especialista em eventos enviará a você o bônus correspondente no seu aniversário!",
  PrivilegeContent4:"(Para garantir que o bônus possa ser entregue normalmente, entre em contato com o especialista de atendimento ao cliente para completar as informações do aniversário!)",
  PrivilegeContent5:"Como solicitar dinheiro para presentes de Natal?",
  PrivilegeContent6:"Regras da loteria: os membros só precisam fazer um depósito válido de mais de 2.000 dentro de 15 dias antes do feriado para serem elegíveis para a loteria",
  PrivilegeContent6_u:"Regras da loteria: os membros só precisam fazer um depósito válido de mais de 2.000 dentro de 15 dias antes do feriado para serem elegíveis para a loteria",
  PrivilegeContent7:"Cerca de serviço 24 horas",
  PrivilegeContent8:"Oferecemos atendimento ao cliente ininterrupto 24 horas por dia, 7 dias por semana, depósitos e saques online e apostas em jogos. Se precisar de ajuda, sinta-se à vontade para consultar o atendimento ao cliente online 24 horas.",
  PrivilegeContent9:"O que é um nome de domínio exclusivo VIP?",
  PrivilegeContent10:"Membros com nível VIP 3 ou superior podem consultar um especialista de linha para enviar uma inscrição.",
  PrivilegeContent11:"Caro membro VIP: Muito obrigado pelo seu apoio e companheirismo contínuos. Contanto que você seja VIP 5 ou superior, você pode solicitar um nome de domínio exclusivo personalizado e desfrutar dos privilégios luxuosos do nome de domínio VIP W69 Entertainment",
  //弹窗
  QueryResult:"resultado da pesquisa",
  Exalted:"Mel:",
  YourCurrentVIPLevel:"Seu nível VIP atual:",
  CurrentActiveBets:"Apostas atualmente válidas:",
  CurrentActBets:"Depósito total atual:",
  
  BetsAreNeededQualify:"Apostas são necessárias para avançar",
  VIPLevelAfterPromotion:"Nível VIP após promoção",
  Close:"fecho",
  Birthday:"bônus de aniversário",
  Holiday:"Bônus de férias",
  Negative:"Ganhos negativos",
  Monthly:"Desconto de depósito mensal",
  exclusiveLine:"Nome de domínio exclusivo",
  exclusiveService:"Atendimento ao cliente dedicado",
  noData:"A conta de membro não existe, insira a conta de membro correta",
  privilege:"privilégio",
  myriad:"Dez mil",
  AHundredMillion:"100 milhões",
  double:"vezes",
  changeLanguage:"linguagem",
  normal:"Membro comum",
  // qianyi:"Centenas de bilhões",
  // wanyi:"trilhões",
  // qianwan:"Dez milhões",
  // wan:"Dez mil",
  // yi:"100 milhões",
  // qianwanyi:"Quadrilhões",
  click_collection:"Obtenha com um clique",
  get_title:"Caros jogadores de plataforma, parabéns por conseguirem",
  get_fail:"Falha ao receber! Por favor, tente novamente mais tarde",
  concern:"Claro",
  tipBottom:"Example: Member A has accumulated deposits since opening the account of 30000 and can upgrade to VIP4. Receive a total upgrade bonus from vip0-vip4 in total. （8+18+38+88+188=340）The higher the cumulative deposit amount, the higher the VIP. At the same time, there are many discounts and special privileges at the VIP level that you can enjoy.",
  tipBottom02: "If you have additional problems, contact the online staff",
  tipBottom03:"",
  // tipBottom:"Observe que todos os valores VND* declarados são calculados em '000 (1VND =1.000 Đồng), por exemplo, 200 VND = 200.000 Đồng.",
  promotionType: {
  1:"bônus de promoção",
  2:"Bônus de férias",
  3:"bônus de aniversário",
  4:"Economia mensal",
  5:"bônus de boa sorte",
  6:"Ganhos negativos"
  }
  }
  