// 印尼语
export const lang = {
	bannerTitle: "Hak istimewa VIP",
	bannerInfo01: "Tingkatkan keanggotaan VIP",
	bannerInfo02: "dapatkan Bonus dan nikmati berbagai macam Promo menarik",
  language_code: "bahasa",
  Game:"Nama Permainan",
  Member:"Username",
  Bonus:"Bonus",
  Name:"Promo",
  query: "Cari",
  vip_query: "Cari Level VIP",
  home_page: "Beranda",
  preferential_activities: "Promosi",
  online_service: "layanan online",
  viplevel_query: "Pencarian Level VIP",
  fill_member_account: "Silahkan input username / ID",
  vip_level: "Mulai {msg},  setiap deposit valid Anda akan otomatis terakumulasi oleh sistem. Ketika jumlah akumulasi mencapai standar tertentu, itu dapat secara otomatis ditingkatkan ke level VIP yang lebih tinggi. Semakin tinggi level VIP, semakin banyak promosi yang Anda ikuti dan semakin banyak bonus yang Anda dapatkan. FAW99 akan terus meluncurkan lebih banyak promosi untuk memberi penghargaan kepada anggota baru dan lama.",
  vip_level_tip: "Sistem akan memperbarui Level VIP dengan bonus Upgrade secara otomatis setelah giliran pemain dihitung.",
  vip_level_tip1: "Taruhan valid Anda pada hari itu akan dihitung oleh sistem lusa, dan level vip Anda juga akan diperbarui dan bonus promosi akan dikeluarkan pada saat itu.",
  vip_t_exam: "Contoh",
  vip_exam: "Catatan: Waktu statistik data dihitung berdasarkan waktu GMT+8.",
  vipTh: [
    "Tingkat VIP",
    "menyetorkan",
    "Bonus Peningkatan VIP",
    "Bonus Keberuntungan VIP",
    "Bonus Hari Ulang Tahun VIP",
    // "Penawaran Setoran Bulanan",
	"Bonus Festival VIP",
    // "Bonus Negatif Mingguan VIP",
    // "Nama Domain Eksklusif VIP",
    "Layanan pelanggan 24 jam"
  ],
  queryTh: [
    "Bonus Upgrade",
    "Bonus Keuntungan",
    "Bonus Ulang Tahun",
    "Bonus Hari Spesial",
    "Bonus Kekalahan Bulanan",
    // "Penawaran Setoran Bulanan",
    // "Domain Eksklusif",
    "Layanan Domain Eksklusif",
  ],
  tabData: [
    "Keuntungan Anggota VIP",
    // "bonus keberuntungan",
    // "Hadiah tabungan bulanan",
    "Deskripsi Keuntungan VIP",
  ],
  query_result: "Hasil Pencarian",
  dear: "Yang terhormat ",
  now_viplevel: "Level sebelumnya",
  current_active_bets: "Total Valid Betting",
  current_save_sum: "Total setoran saat ini",
  next_bet_need: "Jumlah Turnover yang harus dicapai",
  next_need_save: "Deposit diperlukan untuk promosi",
  level_after_promotion: "Level Selanjutnya",
  tab1Th: ["Tingkat VIP", "Setoran bulanan", "Bonus keberuntungan"],
  tab2Th: ["VIP level","Deposit offer","Number of applications","Discount cap","Withdrawal requirements"],
 tab1_p1: "How to apply for Lucky Prize？",
 tab1_p2: "Lucky prizes: No application is required. The system will be issued one after another after 06:00 on the 5st of each month (GMT+8). Due to the large number of prizes, there may be delays in the receipt of the prize. Please wait patiently if it is not received in time.",
 tab1_p2_u: "Lucky Bonus : The system will calculate the end of every 5th day of the month. Contact line within 5-10 days to submit a bonus request.",
 tab1_p3: "Members need to reach the corresponding VIP level and the required effective deposit before they can get the corresponding good luck money.",
 tab1_p4: "Example: The member's VIP level for the current month is VIP3, and the effective deposit for the current month is 5000. The next month can only get 99 good luck money corresponding to VIP level 3.",

 tab3_p1: "Bagaimana cara mengajukan promosi VIP?",
 tab3_p2: "Jika jumlah deposit yang valid mencapai level yang sesuai, Anda dapat secara otomatis meningkatkan ke level VIP yang lebih tinggi.Bonus promosi akan dikeluarkan sekitar pukul 02:00 keesokan harinya (GMT+8).",
 tab3_p3: "Bagaimana cara mengajukan bonus bunga negatif mingguan?",
 tab3_p4: "Diskon ini tidak perlu diterapkan jika profit negatif mingguan member mencapai Rp 3000 atau lebih. Sistem akan mengirimkan bonus yang sesuai kepada Anda Sabtu depan. Tidak ada batasan atas jumlah bonus. Hingga 5% dari bonus transfer perusahaan dapat diperoleh.",
 tab3_p5: "Bagaimana cara mengajukan hadiah ulang tahun?",
 tab3_p6: "Hubungi layanan pelanggan online untuk memberikan sertifikat yang valid, dan spesialis acara akan mengirimkan bonus yang sesuai pada hari ulang tahun Anda!",
 tab3_p7: "",
 tab3_p8: "Bagaimana cara meminta hadiah liburan?",
 tab3_p9: "Tidak perlu mendaftar, anggota hanya perlu menyetorkan deposit yang valid lebih dari 2000IDR dalam waktu 15 hari sebelum festival untuk memenuhi syarat, dan sistem akan mengirimkan Anda bonus yang sesuai pada Hari Tahun Baru, Tahun Baru Imlek, Hari Buruh, dan Hari Nasional.",
 tab3_p9_u: "",
 tab3_p10: "Bagaimana cara mengajukan bonus keberuntungan bulanan?",
 tab3_p11: "Cukup deposit 1000IDR setiap bulan, dan sistem akan secara otomatis mengeluarkan bonus keberuntungan pada tanggal 5 keesokan harinya. Semoga beruntung dan rejeki.",
 tab3_p12: "",
 tab3_p13: "",
 tab3_p14: "Anggota VIP yang terhormat: Terima kasih banyak atas dukungan dan perusahaan Anda yang berkelanjutan. Selama Anda VIP5 atau lebih tinggi, Anda dapat mengajukan permohonan untuk nama domain eksklusif yang disesuaikan dan menikmati hak istimewa mewah dari nama domain VIP hiburan FAW99",
 tab3_p15: "晋级彩金：联系LINE客服申请，系统将在 (GMT+8)时间每月10号传输完毕，10号-15号之内联系LINE客服申请，因彩金数目众多，可能存在延迟到账情况，若未及时到账可多联系几次！",
 tab3_p16: "亏损彩金：联系LINE客服申请，系统将在 (GMT+8)时间每月15号传输完毕，15号-20号联系LINE客服申请，会员月负利达到3000元以上，即可获得公司赠送最高5%转运彩金，彩金金额无上限。",
 
  wan: "Sepuluh ribu",
  qianwan: "sepuluh juta",
  yi: "100 juta",
  qianyi: "100 miliar",
  wanyi: "triliun",
  qianwanyi: "milion lipat empat",
  bei: "waktu",
  vipprivileges: " Keuntungan",
  no_account: "Akun member tidak ada, silahkan masukkan akun member yang benar",
  // 手机端
  officialIndex: "Beranda situs web",
  VIPquery: "Permintaan VIP",
  specialOffer: "Promosi",
  onlineCustomerService: "layanan online",
  Line: "Garis",
  //搜索
  VIPLevelQuery: "Permintaan VIP",
  PleaseInputAccount: "Sila masukkan akun anda",
  Query: "Menanyakan",
  //tab切换1
  MemberAdvantage: "Keuntungan anggota",
  MemberContent: "Dari {msg}, setiap taruhan valid yang Anda hasilkan akan diakumulasikan secara otomatis oleh sistem. Ketika akumulasi mencapai standar tertentu, dapat ditingkatkan secara otomatis ke level vip yang lebih tinggi. Semakin tinggi level VIP, semakin banyak aktivitas preferensial yang dapat diikuti dan semakin banyak bonus yang bisa diperoleh. Platform ini akan terus meluncurkan aktivitas preferensial untuk memberi penghargaan kepada anggota baru dan lama.",
  MemberTips: "Contoh: Anggota a dapat dipromosikan ke vip3 jika akumulasi taruhan yang valid mencapai 100.000 sejak pembukaan akun (Anda bisa mendapatkan 8+18+38+88=152 bonus promosi), dan 300.000 taruhan valid lainnya dapat dipromosikan ke vip4 (bisa mendapatkan 8+18+38+88=152 bonus promosi).Dapatkan 8+18+38+88+188=340 bonus promosi), semakin valid taruhan yang dihasilkan, semakin tinggi level VIP, dan semakin banyak diskon dan hak istimewa VIP yang Anda dapat Nikmati!",
  VIPLevel:"tingkat VIP",
  CumulativeEffectiveBetting:"Akumulasi taruhan yang valid",
  PromotionFiled:"bonus promosi",
  HolidayFiled:"bonus festival",
  BirthdayHandsel:"bonus ulang tahun",
  MonthlyDiscount:"Penawaran Setoran Bulanan",
  LuckyMoney:"bonus keberuntungan",
  NegativeGold:"Bonus negatif",
  ExclusiveDomainName:"Nama domain eksklusif",
  DedicatedService:"Layanan pelanggan eksklusif",
  //tab切换2
  LuckyMoneyTit: "bonus keberuntungan",
  HowApplyLuckyMoney: "Bagaimana cara mengajukan Bonus Keberuntungan?",
  LuckyContent1: "Bonus Keberuntungan: Tidak ada aplikasi yang diperlukan. Sistem akan merilisnya setelah pukul 06:00 (GMT+8) pada tanggal 5 setiap bulan. Karena banyaknya bonus, mungkin ada keterlambatan dalam masuk ke akun. Jika itu terjadi tidak tiba tepat waktu, harap menunggu dengan sabar.",
  LuckyContent1_u: "Bonus keberuntungan: hubungi layanan pelanggan LINE untuk mendaftar, sistem akan menyelesaikan transmisi pada tanggal 5 setiap bulan (GMT+8), dan hubungi layanan pelanggan LINE untuk melamar pada tanggal 5 hingga 10. Karena banyaknya bonus , mungkin ada keterlambatan kedatangan. , jika Anda tidak tiba tepat waktu, Anda dapat menghubungi kami beberapa kali!",
  LuckyContent2: "Anggota harus mencapai level VIP yang sesuai dan setoran valid yang diperlukan sebelum mereka bisa mendapatkan uang keberuntungan yang sesuai.",
  LuckyContent3: "Sebagai contoh: seorang anggota yang level VIPnya adalah VIP3 pada bulan berjalan, deposit efektif pada bulan berjalan adalah 100.000, dan hanya 88 uang keberuntungan yang sesuai dengan level VIP 3 yang dapat diperoleh di bulan berikutnya.",
  LuckyLevel: "tingkat VIP",
  MonthlyDeposit: "Setoran efektif bulanan",
  DowryGoodLuck: "selamat bertunangan",
  //tab切换3
  LavishGifts: "Hadiah tabungan bulanan",
  HowApplyMonthlyDepositDiscount: "Bagaimana cara mengajukan diskon setoran bulanan?",
  LavishContent: "Anggota hanya perlu memiliki 3 deposit dan catatan taruhan 100 atau lebih atau 1 3000 atau lebih dalam satu minggu sebelum setiap aplikasi, dan kemudian menyetor lagi untuk memenuhi syarat untuk berpartisipasi (Diskon hanya berlaku setelah deposit dan aplikasi sebelum permainan!) Detailnya adalah sebagai berikut:",
  Lavishlevel: "tingkat VIP",
  softLoan: "Penawaran Setoran",
  NumberRequested: "Jumlah aplikasi",
  OfferOnline: "Batas penawaran",
  withdrawals: "Permintaan Penarikan",
  //tab切换4
  PrivilegeThat: "Deskripsi Hak Istimewa",
  HowMonthlyNegativeInterestTranshipmentPayment: "Bagaimana cara mengajukan pembayaran transfer bunga negatif bulanan?",
  PrivilegeContent1: "Jika keuntungan negatif bulanan anggota mencapai lebih dari 3.000 yuan, Anda bisa mendapatkan bonus transfer maksimum 5% dari perusahaan. Tidak perlu mengajukan diskon ini. Sistem akan mengirimkan Anda bonus yang sesuai pada tanggal 15 setiap bulan , dan jumlah bonus tidak terbatas.",
  PrivilegeContent2: "Bagaimana cara melamar hadiah ulang tahun?",
  PrivilegeContent3: "Hubungi layanan pelanggan online untuk memberikan sertifikat yang valid, dan spesialis acara akan mengirimkan bonus yang sesuai pada hari ulang tahun Anda!",
  PrivilegeContent4: "(Untuk memastikan bahwa bonus dapat dikirimkan secara normal, harap hubungi spesialis layanan pelanggan untuk melengkapi informasi ulang tahun!)",
  PrivilegeContent5: "Bagaimana cara mengajukan uang hadiah liburan?",
  PrivilegeContent6: "Aturan pembayaran: Anggota hanya perlu melakukan deposit yang valid lebih dari 2.000 dalam waktu 15 hari sebelum festival agar memenuhi syarat untuk pembayaran",
  PrivilegeContent6_u: "Aturan pembayaran: Anggota hanya perlu melakukan deposit yang valid lebih dari 2.000 dalam waktu 15 hari sebelum festival agar memenuhi syarat untuk pembayaran",
  PrivilegeContent7: "Tentang layanan 24 jam",
  PrivilegeContent8: "Kami menyediakan 7X24 jam layanan pelanggan tanpa gangguan, setoran dan penarikan online, dan taruhan permainan.Jika Anda memerlukan bantuan, jangan ragu untuk berkonsultasi dengan layanan pelanggan online 24 jam.",
  PrivilegeContent9: "Apa yang dimaksud dengan nama domain eksklusif VIP?",
  PrivilegeContent10: "Anggota di atas level VIP3 dapat berkonsultasi dengan spesialis lini untuk mengajukan aplikasi.",
  PrivilegeContent11: "Dengan berpartisipasi dalam aktivitas VIP platform, Anda menyetujui 'Aturan dan Ketentuan Umum Promosi'",
  //弹窗
  QueryResult: "hasil pencarian",
  Exalted: "Terhormat:",
  YourCurrentVIPLevel: "Level VIP Anda saat ini:",
  CurrentActiveBets: "Taruhan aktif saat ini:",
  CurrentActBets: "Total simpanan saat ini:",

  BetsAreNeededQualify: "Promosi membutuhkan taruhan",
  VIPLevelAfterPromotion: "Level VIP setelah promosi",
  Close: "penutup",
  Birthday: "hadiah ulang tahun",
  Holiday: "hadiah liburan",
  Negative: "Bonus negatif",
  Monthly: "Penawaran Setoran Bulanan",
  exclusiveLine: "Nama domain eksklusif",
  exclusiveService: "Layanan pelanggan eksklusif",
  noData: "Akun member tidak ada, silahkan masukkan akun member yang benar",
  privilege: "hak istimewa",
  myriad: "Sepuluh ribu",
  AHundredMillion: "100 juta",
  double: "waktu",
  changeLanguage: "bahasa",
  normal: "Anggota biasa",
  // qianyi:"100 miliar",
  // wanyi:"triliun",
  // qianwan:"sepuluh juta",
  // wan:"Sepuluh ribu",
  // yi:"100 juta",
  // qianwanyi:"milion lipat empat",
  click_collection: "Satu klik untuk menerima",
  get_title: "Pemain platform yang terhormat, selamat untuk mendapatkan",
  get_fail: "Gagal menerima! Silakan coba lagi nanti",
  concern: "Tentu",
  tipBottom:"Contoh: Member A dapat dipromosikan ke VIP4 (Anda bisa mendapatkan 9+19+39+99+199=365 (Bonus Peningkatan VIP) sejak pembukaan akun, jumlah akumulatif mencapai 30000, semakin banyak akumulasi setoran valid, semakin tinggi level VIP, dan pada saat yang sama dapat menikmati akan ada lebih banyak diskon dan hak istimewa vip!",
  tipBottom02:"Jika Anda memiliki pertanyaan, silakan hubungi layanan pelanggan online platform",
  tipBottom03:"Rasio jumlah Indonesia: 1:1000",
  promotionType: {
    1: "bonus promosi",
    2: "bonus festival",
    3: "bonus ulang tahun",
    4: "setoran bulanan",
    5: "bonus keberuntungan",
    6: "Bonus negatif"
  }
}