export const lang = {
	bannerTitle: "VIP特权",
	bannerInfo01: "升级VIP会员",
	bannerInfo02: "获得各种特别的优惠活动",
  language_code: "语言",
  Game:"游戏名称",
  Member:"会员账号",
  Bonus:"奖金",
  Name:"优惠名称",
  query: "搜索",
  vip_query: "VIP等级查询",
  home_page: "首页",
  preferential_activities: "优惠活动",
  quizNav: "竞猜",
  online_service: "在线客服",
  viplevel_query: "VIP等级查询",
  fill_member_account: "请输入会员账号",
  vip_tit: "Vip彩金",
  vip_level: "自{msg}起，您的每笔存款，系统都会自动累计，当累计存款达到一定标准后，就可以自动提升vip等级。Vip等级越高，参与的优惠就更多，拿到的奖励也更多。",
  vip_level_tip: "您当日的有效投注，系统会在后天进行统计，届时也会更新您的vip等级以及发放晋级彩金  。",
  vip_level_tip1: "您当日的有效投注，系统会在后天进行统计，届时也会更新您的vip等级以及发放晋级彩金  。",
  vip_t_exam: "例",
  vip_exam: "柬埔寨使用货币为： USD",
  vipTh: [
    "VIP",
    "累计存款",
    "晋级彩金",
    "好运彩金",
    "生日彩金",
    // "月月存款优惠",
    "节日彩金",
    "负利彩金",
    // "专属域名",
    "24小时客服"
  ],
  // vipTh: [
  //   "VIP等级",
  //   "累计有效投注",
  //   "晋级彩金",
  //   "节日彩金",
  //   "生日彩金",
  //   // "月月存款优惠",
  //   "好运彩金",
  //   "负利彩金",
  //   "专属域名",
  //   "专属客服"
  // ],
  queryTh: [
    "晋级彩金",
    "幸运彩金",
    "生日彩金",
    "节日彩金",
    "负利彩金",
  ],
  tabData: [
    "VIP特权",
    // "好运彩金",
    // "月存豪礼",
    "特权说明",
  ],
  query_result: "查询结果",
  dear: "亲爱的",
  now_viplevel: "您当前的VIP等级",
  current_active_bets: "当前有效投注",
  current_save_sum: "当前总存款",
  next_bet_need: "晋级还需投注",
  next_need_save: "晋级还需存款",
  level_after_promotion: "晋级后VIP等级",
  tab2_p1: "如何申请月月存款优惠？",
  tab2_p2: "会员仅需在每次申请前一周内有3笔100以上或1笔3000以上的存款及投注记录，再次存款即可具备参与资格。（优惠仅在存款后未进行游戏前申请有效！）详情如下：",
  tab1Th: ["VIP等级","存款","彩金"],
  tab2Th: ["VIP等级","存款优惠","申请次数","优惠上限","提款要求"],
  tab1_p1: "如何申请vip幸运彩金？",
  tab1_p2: "不需要申请，只要vip等级达到1以上，且上个月累计存款超过30美金，就可以在这月的5号06:00（GMT+8）后收到系统自动派送的幸运彩金。（由于派送的人数比较多，可能会延迟一点时间，请耐心等待）",
  tab1_p2_u: "好运彩金：联系LINE客服申请，系统将在 (GMT+8)时间每月5号传输完毕，5号-10号之内联系LINE客服申请，因彩金数目众多，可能存在延迟到账情况，若未及时到账可多联系几次！",
  tab1_p3: "",
  tab1_p4: "举例：会员vip等级为3，当月累计存款为30美金，下个月只能获得0.5幸运彩金（要拿到3美金，需要上个月累计存款达到150美金）",
  
  tab3_p1: "如何申请每个月vip负利彩金？",
  tab3_p2: "不需要申请，只要在上个月实际负利额度达到100美金以上，就会在这个月的15号02:00（GMT+8）后收到系统自动派送的负利彩金。该彩金没有上限，最高赠送比例为负利额度的5%。",
  tab3_p3: "如何申请生日彩金？",
  tab3_p4: "达到vip3等级，在生日当天联系客服提供身份证件，就可以领取到faw99为您准备的生日彩金了",
  tab3_p5: "如何申请节日彩金？",
  tab3_p6: "不需要申请，只要在 新年 亡人节 送水节开始的前15天，有累计存款50美金，就可以在节日当天收到系统自动派送的节日祝福彩金",
  tab3_p7: "",
  tab3_p8: "",
  tab3_p9: "",
  tab3_p9_u: "",
  tab3_p10: "",
  tab3_p11: "",
  tab3_p12: "",
  tab3_p13: "",
  tab3_p14: "FAw99保留随时修改、取消、暂停或终止促销活动或更改促销活动条款的权利，恕不另行通知。",
  tab3_p15: "",
  tab3_p16: "",
  
  wan: "万",
  qianwan: "千万",
  yi: "亿",
  qianyi: "千亿",
  wanyi: "万亿",
  qianwanyi: "千万亿",
  bei: "倍",
  vipprivileges: "特权",
  no_account: "会员账号不存在，请输入正确的会员账号",
  // 手机端
  officialIndex: "首页",
  VIPquery: "VIP等级查询",
  specialOffer: "优惠活动",
  onlineCustomerService: "在线客服",
  Line: "Line",
  //搜索
  VIPLevelQuery: "VIP查询",
  PleaseInputAccount: "请输入会员账号",
  Query: "查询",
  //tab切换1
  MemberAdvantage: "会员优势",
  MemberContent: "自{msg}起，您所产生的每一笔有效投注，系统都会自动累计，当累计达到一定标准时，可自动升级为更高级别的vip。VIP等级越高，能参与更多的优惠活动、收获更多的彩金，平台将不断推出更多的优惠活动回馈新老会员。",
  MemberTips: "例：会员a自开户起，累计有效投注达到10万，即可晋级为vip3（可以获得8+18+38+88=152晋级彩金），再产生30万有效投注即可晋级为vip4（可以获得8+18+38+88+188=340晋级彩金），产生的有效投注越多，vip等级越高，同时可以享受到的vip优惠以及特权也会更多哦！",
  VIPLevel:"VIP等级",
  CumulativeEffectiveBetting:"累计有效投注",
  PromotionFiled:"晋级彩金",
  HolidayFiled:"节日彩金",
  BirthdayHandsel:"生日彩金",
  MonthlyDiscount:"月月存款优惠",
  LuckyMoney:"幸运彩金",
  NegativeGold:"负利彩金",
  ExclusiveDomainName:"专属域名",
  DedicatedService:"专属客服",
  //tab切换2
  LuckyMoneyTit: "好运彩金",
  HowApplyLuckyMoney: "如何申请好运彩金？",
  LuckyContent1: "好运彩金：无需申请，系统将在(GMT+8)时间每月5号06:00后陆续发放，因彩金数目众多，可能存在延迟到账情况，若未及时到账请耐心等待。",
  LuckyContent1_u: "好运彩金：联系LINE客服申请，系统将在 (GMT+8)时间每月5号传输完毕，5号-10号之内联系LINE客服申请，因彩金数目众多，可能存在延迟到账情况，若未及时到账可多联系几次！",
  LuckyContent2: "会员需先达到相对应的VIP等级同时达到所需的有效存款，才能获取对应的好运金。",
  LuckyContent3: "例：会员当月VIP等级为VIP3,当月有效存款为10万，次月只可获取VIP等级3对应的88好运金。",
  LuckyLevel: "VIP等级",
  MonthlyDeposit: "存款",
  DowryGoodLuck: "彩金",
  //tab切换3
  LavishGifts: "月存豪礼",
  HowApplyMonthlyDepositDiscount: "如何申请月月存款优惠？",
  LavishContent: "会员仅需在每次申请前一周内有3笔100以上或1笔3000以上的存款及投注记录，再次存款即可具备参与资格.(优惠仅在存款后未进行游戏前申请有效!)详情如下:",
  Lavishlevel: "VIP等级",
  softLoan: "存款优惠",
  NumberRequested: "申请次数",
  OfferOnline: "优惠上限",
  withdrawals: "提款要求",
  //tab切换4
  PrivilegeThat: "特权说明",
  HowMonthlyNegativeInterestTranshipmentPayment: "如何申请月月负利转运金？",
  PrivilegeContent1: "会员月负利达到3000元以上，即可获得公司赠送最高5%转运彩金，该优惠无需申请，系统会在每月15号为您派送相应彩金，彩金金额无上限。",
  PrivilegeContent2: "如何申请生日礼金？",
  PrivilegeContent3: "联系在线客服提供有效证件，活动专员会在您生日当天为您派送相应彩金！",
  PrivilegeContent4: "（为了保证彩金能正常派送，请您联系客服专员完善生日信息！）",
  PrivilegeContent5: "如何申请节日礼金？",
  PrivilegeContent6: "派彩规则:会员仅需在节日前15天内有效存款2000以上即可具备派彩资格",
  PrivilegeContent6_u: "派彩规则:会员仅需在节日前15天内有效存款2000以上即可具备派彩资格",
  PrivilegeContent7: "关于24小时服务",
  PrivilegeContent8: "我们为您提供7X24小时不间断的客服服务、在线存取款以及游戏投注，如需帮助，请随时咨询24小时在线客服。",
  PrivilegeContent9: "什么是VIP专属域名？",
  PrivilegeContent10: "VIP3级别以上会员，可咨询line专员提交申请。",
  PrivilegeContent11: "尊敬的VIP会员：非常感谢您一直以来的支持和陪伴。只要你是VIP5以上，都可以申请定制专属域名，享受W69娱乐VIP域名的豪华特权",
  //弹窗
  QueryResult: "查询结果",
  Exalted: "亲爱的：",
  YourCurrentVIPLevel: "您当前的VIP等级：",
  CurrentActiveBets: "当前有效投注：",
  CurrentActBets: "当前总存款：",

  BetsAreNeededQualify: "晋级还需投注",
  VIPLevelAfterPromotion: "晋级后VIP等级",
  Close: "关闭",
  Birthday: "生日彩金",
  Holiday: "节日彩金",
  Negative: "负利彩金",
  Monthly: "月月存款优惠",
  exclusiveLine: "专属域名",
  exclusiveService: "专属客服",
  noData: "会员账号不存在，请输入正确的会员账号",
  privilege: "特权",
  myriad: "万",
  AHundredMillion: "亿",
  double: "倍",
  changeLanguage: "语言",
  normal: "普通会员",
  // qianyi:"千亿",
  // wanyi:"万亿",
  // qianwan:"千万",
  // wan:"万",
  // yi:"亿",
  // qianwanyi:"千万亿",
  click_collection: "一键领取",
  get_title: "尊敬的 平台 玩家，恭喜您获得",
  get_fail: "领取失败！请稍后再试",
  concern: "确 定",
  tipBottom:"举例：新会员一天内存款1000美金，就可以在第二天升级为vip4，可以获得11.3（0.3+0.5+1.5+3+6=11.3）的晋级彩金",
  tipBottom02: "",
  tipBottom03: "",
  // tipBottom:"请注意，所有规定的越南盾*金额均按 '000 (1VND =1,000 Đồng) 计算，例如200 越南盾 = 200,000 Đồng",
  promotionType: {
    1: "晋级彩金",
    2: "节日彩金",
    3: "生日彩金",
    4: "月月存",
    5: "好运彩金",
    6: "负利彩金"
  }
}