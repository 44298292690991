export const lang = {
	bannerTitle: "VIPឯកសិទ្ធិ",
	bannerInfo01: "តម្លើងកម្រិតសមាជិកVIP",
	bannerInfo02: "ទទួលបានប្រូម៉ូសិនពិសេសផ្សេងៗ",
	language_code: "language",
	Game: "ហ្គេម",
	Member: "សមាជិក",
	Bonus: "ប្រាក់រង្វាន់",
	Name: "ឈ្មោះប្រម៉ូសិន",
	query: "ស្វែងរក",
	vip_query: "កម្រិតVIP",
	home_page: "ទំព័រ",
	preferential_activities: "ប្រូម៉ូសិន",
	quizNav: "សំណួរ",
	online_service: "Customer service",
	viplevel_query: "ការស្វែងរកកម្រិតVIP",
	fill_member_account: "គណនេយ្យ",
	vip_tit: "ប្រាក់រង្វាន់VIP",
	vip_level: "ចាប់ពីថ្ងៃទី{msg}តទៅ ប្រព័ន្ធដាក់ប្រាក់នីមួយៗរបស់អ្នកដោយស្វ័យប្រវត្តិ។ នៅពេលដែលដាក់ប្រាក់ដល់ស្តង់ដារជាក់លាក់មួយ កម្រិត VIP អាចកើនឡើងដោយស្វ័យប្រ  ត្តិ។ កម្រិត VIP កាន់តែខ្ពស់ អ្នកអាចចូលរួមប្រូម៉សិននិងប្រាក់រង្វាន់កាន់តែច្រើន។",
	vip_level_tip: "Your valid bets on the day will be counted the day after tomorrow, and your VIP level will be updated and promotion bonuses will be issued at that time.",
	vip_level_tip1: "Your valid bets on the day will be counted the day after tomorrow, and your VIP level will be updated and promotion bonuses will be issued at that time.",
	vip_t_exam: "Example",
	vip_exam: "រូបិយប័ណ្ណប្រើនៅកម្ពុជា: USD",
	vipTh: [
		"កម្រិត VIP",
		"ចំនួនដាក់ប្រាក់",
		"រង្វាន់តម្លើងVIP",
		"រង្វាន់សំណាង",
		"រង្វាន់ខួបកំណើត",
		"រង្វាន់ថ្ងៃបុណ្យ",
		"រង្វាន់ភ្នាល់ចាញ់",
	],
	queryTh: [
		"ក់រង្វាន់តម្លើងVIP",
		"ប្រាក់រង្វាន់សំណាង",
		"ប្រាក់រង្វាន់ខួបកំណើត",
		"ប្រាក់រង្វាន់ពិធីបុណ្យ",
		"ប្រាក់រង្វាន់ចាញ់ភ្នាល់",
	],
	tabData: [
		"ឯកសិទ្ធិ VIP",
		// "Lukcy Bonus ",
		// "Monthly deposit offer",
		"ការពិពណ៌នាអំពីសិទ្ធិ",
	],
	query_result: "លទ្ធផលស្វែងរក",
	dear: "សូមគោរព",
	now_viplevel: "កម្រិតVIPបច្ចុប្បន្នរបស់អ្នក៖",
	current_active_bets: "current valid bet",
	current_save_sum: "ប្រាក់សរុប",
	next_bet_need: "ទាមទារការភ្នាល់",
	next_need_save: "បញ្ញីដាក់ប្រាក់",
	level_after_promotion: "កម្រិតថ្នាក់",
	tab2_p1: "How to apply for monthly deposit discount",
	tab2_p2: "Members only need to have 3 deposits of 100 or more or 1 deposit and betting records of 3000 or more in the week before each application, and deposit again to qualify for participation. (The offer is only valid after depositing and not applying before the game!) The details are as follows:",
	tab1Th: ["កម្រិត VIP", "ដាក់ប្រាក់", "ប្រាក់រង្វាន់"],
	tab2Th: ["VIP level", "Deposit offer", "Number of applications", "Discount cap", "Withdrawal requirements"],
	tab1_p1: "តើត្រូវដាក់ពាក្យសុំប្រាក់រង្វាន់ vip សំណាងដោយរបៀបណា?",
	tab1_p2: "មិនចាំបាច់ដាក់ពាក្យទេ ដរាបណាកម្រិត VIP ឡើងដល់ 1 ឬខ្ពស់ជាងនេះ ហើយចំនួនសរុបនៃការដាក់ប្រាក់ក្នុងខែមុនលើសពី 30 ដុល្លា អ្នកអាចទទួលបានប្រាក់រង្វាន់សំណាងដោយប្រព័ន្ធស្វ័យប្រវត្តិបន្ទាប់ពីម៉ោង 06:00 ថ្ងៃទី 5 នៃខែនេះ។ (GMT+8)។ (ដោយសារចំនួនមនុស្សច្រើន អាចមានការយឺតយ៉ាវបន្តិច សូមមេត្តាទន្ទឹងរងចាំ)",
	tab1_p2_u: "Lucky Bonus : The system will calculate the end of every 5th day of the month. Contact line within 5-10 days to submit a bonus request.",
	tab1_p3: "ឧទាហរណ៍៖ សមាជិកA VIP3 ហើយដាក់ប្រាក់ក្នុងខែនេះមានចំនួនសរុប 30 ដុល្លារខែបន្ទាប់នឹងទទួលបានប្រាក់រង្វាន់សំណាងតែ0.5ប៉ុណ្ណោះ។",
	tab1_p4: "(ដើម្បីទទួលបាន $3 អ្នកត្រូវមានចំនួននៃការដាក់ប្រាក់សរុប$150 ក្នុងខែមុន)",

	tab3_p1: "តើត្រូវដាក់ពាក្យស្នើសុំប្រាក់រង្វាន់ភ្នាល់ចាញ់ប្រចាំខែយ៉ាងដូចម្តេច?",
	tab3_p2: "មិនចាំបាច់ដាក់ពាក្យស្នើសុំទេ គ្រាន់តែអ្នកលេងចាញ់ជាង 100 ដុល្លាកាលពីខែមុន អ្នកនឹងទទួលបានប្រាក់រង្វាន់ភ្នាល់ចាញ់ដោយប្រព័ន្ធស្វ័យប្រវត្តិបន្ទាប់ពីម៉ោង 02:00 នៅថ្ងៃទី 15 នៃខែនេះ (GMT+8 ) ។ ​ប្រាក់រង្វាន់គ្មានដែនកំណត់ ហើយផ្តល់ជូនដល់ទៅ5% នៃចំនួនលុយភ្នាល់ចាញ់។",
	tab3_p3: "តើត្រូវដាក់ពាក្យសុំប្រាក់រង្វាន់ខួបកំណើតយ៉ាងដូចម្តេច?",
	tab3_p4: "ចាប់ពីកម្រិត VIP3 នៅថ្ងៃកំណើតរបស់អ្នកសូមទាក់ទងខាងផ្នែកបម្រើសេវាអតិថិជនផ្តល់អត្តសញ្ញាណប័ណ្ណរបស់អ្នក ហើយអ្នកអាចទទួលបានប្រាក់រង្វាន់ថ្ងៃកំណើតដែលFaw99នឹងរៀបចំសម្រាប់អ្នក។",
	tab3_p5: "តើត្រូវដាក់ពាក្យសុំប្រាក់រង្វាន់ថ្ងៃពិធីបុណ្យដោយរបៀបណា?",
	tab3_p6: "មិនតម្រូវឱ្យដាក់ពាក្យទេ គឺគ្រាន់តែក្នុងអំឡុងពេល ចូលឆ្នាំខ្មែរ，បុណ្យភ្ជុំបិណ្ឌឬបុណ្យអ៊ំទូក ចាប់ផ្តើមមុនថ្ងៃបុណ្យ15ថ្ងៃ，ដាក់ប្រាក់ចំនួន50ដុល្លា，អ្នកនឹងអាចទទួលបានប្រាក់រង្វាន់ថ្ងៃបុណ្យដែលដោយប្រព័ន្ធស្វ័យប្រវត្តិនៅថ្ងៃបុណ្យនោះ។",
	tab3_p7: "",
	tab3_p8: "",
	tab3_p9: "",
	tab3_p9_u: "",
	tab3_p10: "",
	tab3_p11: "",
	tab3_p12: "",
	tab3_p13: "",
	tab3_p14: "FAw99សូមរក្សាសិទ្ធិក្នុងការកែប្រែ លុបចោល ព្យួរឬបញ្ចប់ប្រម៉ូសិន ឬផ្លាស់ប្ដូរលក្ខន្តិកៈនៃប្រម៉ូសិនគ្រប់ពេលវេលាដោយគ្មានការជូនដំណឹងជាមុន។",
	tab3_p15: "Promotion bonus: contact LINE customer service to apply, the system will complete the transmission on the 10th of each month (GMT+8), and contact LINE customer service to apply within the 10th to the 15th. Due to the large number of bonuses, there may be a delay in arriving into the account. If you do not arrive in time, please contact us several times!",
	tab3_p16: "Lost bonus: contact LINE customer service to apply, the system will complete the transmission on the 15th of each month (GMT+8), and contact LINE customer service from the 15th to the 20th to apply. If the monthly negative profit of members reaches more than 3,000 yuan, the company can get the highest gift from the company. 5% transfer bonus, there is no upper limit on the bonus amount.",


	wan: "ten thousand",
	qianwan: "ten million",
	yi: "100 million",
	qianyi: "100 billion",
	wanyi: "trillion",
	qianwanyi: "quadrillion",
	bei: " ×1",
	vipprivileges: "ការអនុញ្ញាត",
	no_account: "អត់ឃើញគណនេយ្យ",
	// 手机端
	officialIndex: "ទំព័រ",
	VIPquery: "កម្រិតVIP",
	specialOffer: "ូម៉ូសិន",
	onlineCustomerService: "Customer Service",
	Line: "Line",
	//搜索
	VIPLevelQuery: "កម្រិតVIP",
	PleaseInputAccount: "គណនេយ្យ",
	Query: "Search",
	//tab切换1
	MemberAdvantage: "ឯកសិទ្ធិ VIP",
	MemberContent: "From {msg}, every valid bet you make will be automatically accumulated by the system. When the accumulated amount reaches a certain standard, it can be automatically upgraded to a higher level VIP.The higher the VIP level, the more preferential activities you can participate in and the more bonuses you can get. Platform will continue to launch more preferential activities to reward new and old members.",
	MemberTips: "Example: Member a has accumulated effective bets of 100,000 since opening an account and can be promoted to VIP3 (8+18+38+88=152 promotion prizes can be obtained), and 300,000 effective bets can be generated to be promoted to vip4 (8+18+38+88+188=340 promotion bonus), the more valid bets generated, the higher the VIP level, and the more VIP discounts and privileges you can enjoy!",
	VIPLevel: "VIP level",
	CumulativeEffectiveBetting: "Amount Deposit",
	PromotionFiled: "ក់រង្វាន់តម្លើងVIP",
	HolidayFiled: "Festival VIP Bonus",
	BirthdayHandsel: "VIP Birthday Bonus",
	MonthlyDiscount: "Monthly deposit offer",
	LuckyMoney: "ប្រាក់រង្វាន់សំណាង",
	NegativeGold: "Monthly Negative Bonus VIP",
	ExclusiveDomainName: "VIP Exclusive Domain Name",
	DedicatedService: "Customer service 24 hours",
	//tab切换2
	LuckyMoneyTit: "Lukcy Bonus ",
	HowApplyLuckyMoney: "How to apply for Lucky Prize？",
	LuckyContent1: "Lucky prizes: No application is required. The system will be issued one after another after 02:00 on the 5st of each month (GMT+8). Due to the large number of prizes, there may be delays in the receipt of the prize. Please wait patiently if it is not received in time.",
	LuckyContent1_u: "Lucky Bonus : The system will calculate the end of every 5th day of the month. Contact line within 5-10 days to submit a bonus request.",
	LuckyContent2: "Members need to reach the corresponding VIP level and the required effective deposit before they can get the corresponding good luck money.",
	LuckyContent3: "Example: The member's VIP level for the current month is VIP3, and the effective deposit for the current month is 100,000. The next month can only get 88 good luck money corresponding to VIP level 3.",
	LuckyLevel: "កម្រិត VIP",
	MonthlyDeposit: "ដាក់ប្រាក់",
	DowryGoodLuck: "ប្រាក់រង្វាន់",
	//tab切换3
	LavishGifts: "Monthly deposit offer",
	HowApplyMonthlyDepositDiscount: "How to apply for monthly deposit discount？",
	LavishContent: "Members only need to have 3 deposits of 100 or more or 1 deposit and betting records of 3000 or more in the week before each application, and deposit again to qualify for participation. (The offer is only valid after depositing and not applying before the game!) The details are as follows:",
	Lavishlevel: "VIP level",
	softLoan: "Deposit offer",
	NumberRequested: "Number of applications",
	OfferOnline: "Discount cap",
	withdrawals: "Withdrawal requirements",
	//tab切换4
	PrivilegeThat: "ការពិពណ៌នាអំពីសិទ្ធិ",
	HowMonthlyNegativeInterestTranshipmentPayment: "How to apply for monthly negative transfer payment？",
	PrivilegeContent1: "If the member’s monthly negative profit reaches 3,000 THB or more, you can get up to 5% transfer bonus from the company. This discount does not need to apply. The system will send you the corresponding bonus on the 15rd of each month. There is no upper limit on the bonus amount.",
	PrivilegeContent2: "How to apply for birthday gift？",
	PrivilegeContent3: "Contact online customer service to provide a valid certificate, and the event specialist will send you the corresponding bonus on your birthday!",
	PrivilegeContent4: "（In order to ensure the normal delivery of the winnings, please contact the customer service specialist to complete the birthday information!）",
	PrivilegeContent5: "How to apply for holiday gifts？",
	PrivilegeContent6: "Lottery rules: Members only need to make a valid deposit of more than 2000 within 15 days before the holiday to be eligible for lottery.",
	PrivilegeContent6_u: "Lottery rules: Members only need to make a valid deposit of more than 2000 within 15 days before the holiday to be eligible for lottery.",
	PrivilegeContent7: "About 24-hour service",
	PrivilegeContent8: "We provide you with 24*7 hours of uninterrupted customer service, online deposit and withdrawal, and game betting. If you need help, please feel free to consult 24-hour online customer service.",
	PrivilegeContent9: "What is a VIP exclusive domain name？",
	PrivilegeContent10: "Members above VIP3 level can consult the line specialist to submit an application.",
	PrivilegeContent11: 'Participating in Platform VIP activities means that you agree to the "General Preferential Rules and Terms',
	//弹窗
	QueryResult: "លទ្ធផលស្វែងរក",
	Exalted: "សូមគោរព：",
	YourCurrentVIPLevel: "កម្រិត vip បច្ចុប្បន្ន：",
	CurrentActiveBets: "ការភ្នាល់ដែលមានសុពលភាពបច្ចុប្បន្ន：",
	CurrentActBets: "ប្រាក់បញ្ញើសរុប：",

	BetsAreNeededQualify: "ទាមទារការភ្នាល់",
	VIPLevelAfterPromotion: "កម្រិតថ្នាក់",
	Close: "Close",
	Birthday: "ប្រាក់រង្វាន់ខួបកំណើត",
	Holiday: "ប្រាក់រង្វាន់ពិធីបុណ្យ",
	Negative: "ប្រាក់រង្វាន់ចាញ់ភ្នាល់",
	Monthly: "monthly deposit ",
	exclusiveLine: "Exclusive domain",
	exclusiveService: "Exclusive domain service",
	noData: "អត់ឃើញគណនេយ្យ",
	privilege: " privilege",
	myriad: "ten thousand",
	AHundredMillion: "100 million",
	double: " ×1",
	changeLanguage: "Language",
	click_collection: "One-click collection",
	get_title: "Dear Platform players, congratulations on getting",
	get_fail: "Failed to claim! Please try again later",
	concern: "confirm",
	tipBottom: "ឧទាហរណ៍៖ សមាជិកថ្មីដែលដាក់ប្រាក់ 1,000 ដុល្លារក្នុងរយៈពេលមួយថ្ងៃអាចដំឡើងទៅ vip4 នៅថ្ងៃទីពីរ និងទទួលបានប្រាក់រង្វាន់លើកទឹកចិត្ត 11.3 (0.3+0.5+1.5+3+6=11.3)",
	tipBottom02: "ប្រសិនបើអ្នកជួបប្រទះបញ្ហាណាមួយ",
	tipBottom03: "សូមទាក់ទងសេវាកម្មអតិថិជនតាមអ៊ីនធឺណិត",
	tipBottom04: "Vietnam / Indonesia amount ratio: 1:1000",
	promotionType: {
		1: "Upgrade bonus",
		2: "Holiday bonus",
		3: "Birthday bonus",
		4: "Monthly deposit offer",
		5: "Lucky prizes",
		6: "Negative profit subsidy"
	}
}
